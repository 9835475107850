export default {
  fr: {
    "App": {
      "menu": {
        "menuTitle": "Navigatrice",
        "Home": "Maison",
        "Data Sources": "Source d'information",
        "Add Data Sources": "Ajouter des sources de données",
        "View Data Sources": "Afficher les sources de données",
        "Facility Registry": "Registre des installations",
        "Search": "Chercher",
        "Search Facility": "Installation de recherche",
        "Search Jurisdiction": "Recherche juridiction",
        "Search Organization": "Organisation de recherche",
        "Search Service": "Service de recherche",
        "Search Facility Add Request": "Installation de recherche Ajouter une demande",
        "Add Jurisdiction": "Ajouter la juridiction",
        "Add Facility": "Ajouter une installation",
        "Add Organization": "Ajouter une organisation",
        "Add Service": "Ajouter un service",
        "Requests": "Demandes",
        "Request Addition of New Facility": "Demander l'ajout de nouvelles installations",
        "Request Update of Facility Details": "Demander la mise à jour des détails de l'installation",
        "Facility Reconciliation": "Réconciliation des installations",
        "Create and Manage Data Source Pair": "Créer et gérer la paire de sources de données",
        "Reconcile": "Réconcilier",
        "Reconciliation Status": "Statut de réconciliation",
        "Pair Side to Side View": "Paire une vue côté latérale",
        "Map": "Carte",
        "configure System": "Configurer le système",
        "Account": "Compte",
        "Add User": "Ajouter l'utilisateur",
        "Users List": "Liste des utilisateurs",
        "Roles Management": "Gestion des rôles",
        "Apps": "applications"
      },
      "gofr-codesystem": {},
      "gofr-search": {},
      "gofr-search-term": {},
      "gofr-search-reference-term": {},
      "gofr-search-code": {},
      "gofr-array": {},
      "gofr-complex-card": {},
      "gofr-questionnaire": {},
      "gofr-questionnaire-section": {},
      "gofr-questionnaire-group": {},
      "gofr-resource": {},
      "fhir-string": {},
      "fhir-resources-texts": {
        "Basic Details ": "Détails de base",
        " uncategorized details": "Détails non classés",
        "Facility Name": "Nom de l'installation",
        "Alternative/Nick Names": "Noms alternatifs / Nick",
        "Description": "La description",
        "Status": "Statut",
        "Facility Types": "Types d'installation",
        "Physical Type": "Type physique",
        "Parent": "Parente",
        "Request Status": "Statut de demande",
        "Identifiers": "Identifiants",
        "Identifiers for the facility": "Identificateurs de l'installation",
        "Identifier": "Identifiant",
        "System": "Système",
        "ID Number": "Numéro d'identification",
        "ID Type": "Type d'identité",
        "Geo-Coordinates": "Géo-coordonne",
        "Geo-coordinates for the facility": "Géo-coordonnées pour l'installation",
        "Longitude": "Longitude",
        "Latitude": "Latitude",
        "Altitude": "Altitude",
        "Contacts": "Contacts",
        "Contacts for the facility": "Contacts pour l'installation",
        "Contact Type": "type de contact",
        "Value": "Évaluer",
        "Contact Use": "Utilisation des contacts",
        "Address": "Adresse",
        "Address Use": "Utilisation d'adresse",
        "Address Type": "Type d'adresse",
        "Street Address": "Adresse de rue",
        "City": "Ville",
        "District": "District",
        "State": "État",
        "Postal Code": "code postal",
        "Country": "Pays",
        "Hours of operation": "Heures d'ouverture",
        "Facility availability": "Disponibilité des installations",
        "Availability": "Disponibilité",
        "Days of week": "Jours de la semaine",
        "All day": "Toute la journée",
        "Opening time": "Horaire d'ouverture",
        "Closing time": "Heure de fermeture",
        " Basic Details": "Détails de base",
        "Name": "Nom",
        "Type": "Taper",
        "Attach To Hierarchy": "Attacher à la hiérarchie",
        "Parent Type": "Type de parent",
        "Managing Organization": "Organisation de gestion",
        "Office Contacts": "Contacts de bureau",
        "Contact Person": "Personne de contact",
        "Purpose": "Objectif",
        "Given Name": "Prénom",
        "Family Name": "Nom de famille",
        "Telecom Use": "Utilisation des télécommunications",
        "Telecom System": "Système de télécommunications",
        "Mobile Phone": "Téléphone mobile",
        "Work Email": "Email de travail",
        "Organization": "Organisme",
        "Organization Names": "Noms d'organisation",
        "Organization Type": "Type d'Organisation",
        "Jurisdiction Name": "Nom de juridiction",
        "Jurisdiction Type": "Type de juridiction",
        "Oranization Names": "Noms d'oranisation",
        "Oranization Type": "Type d'oranisation",
        "Geo-coordinates for the jurisdiction": "Géo-coordonnées pour la juridiction",
        "Boundaries": "Les frontières",
        "Boundary": "Frontière",
        "Jurisdictions": "Juridictions",
        "Details": "Détails",
        "Jurisdiction details": "Détails de la juridiction",
        "Nickname": "Surnom",
        "Location Type": "Type de lieu",
        "Organizations Managing This Jurisdiction": "Organisations gérant cette juridiction",
        "Edit": "Éditer",
        "Actions": "Actions",
        "Facility Geo-Coordinates": "Géo-coordonnées de l'installation",
        "Co-ordinates": "Coordonnée",
        "Location": "Emplacement",
        "": "",
        "Facility Type": "Type d'installation",
        "Facility Physical Type": "Type physique de l'installation",
        "Longitute": "Longituter",
        "GOFR Facility Add Request": "Installation GOFR Ajouter une demande",
        "Basic Details": "Détails de base",
        "Affected Resource": "Ressource affectée",
        "Facility Identifiers": "Identificateurs des installations",
        "Use": "Utilisation",
        "Facility Address": "Adresse",
        "Line": "Ligne",
        "Telecom": "Télécommunications",
        "Open all day": "Ouvert toute la journée",
        "Time that the facility is open": "Temps que l'installation est ouverte",
        "Time that the facility closes": "Temps que l'installation ferme",
        "Facilities": "Installations",
        "Exclude Facilities Of": "Exclure les installations de",
        "Contact Details": "Détails du contact",
        "Address, email, phone numbers": "Adresse, e-mail, numéros de téléphone",
        "Business hours": "Heures de travail",
        "Organizations Managing This Facility": "Organisations gérant cette installation",
        "Active": "Active",
        "mCSD HealthcareService": "MCSD HealthCaReService",
        "Facility": "Facilité",
        "Healthcare service details": "Détails du service de santé",
        "Broad Category of Service": "Large catégorie de service",
        "Type of Service": "Type de service",
        "Specialty": "Spécialité",
        "Comment about this service": "Commentez ce service",
        "Service Provision Condition": "Condition de prestation de services",
        "Eligibility": "Admissibilité",
        "Programs": "Programmes",
        "Communication Language": "Langue de communication",
        "Referral Methods": "Méthodes de référence",
        "Appointment Required": "Rendez-vous requis",
        "Service Time Availability": "Disponibilité du temps de service",
        "Available all day": "Disponible toute la journée",
        "Opening Time": "Horaire d'ouverture",
        "Closing Time": "Heure de fermeture",
        "Service Unavailability": "Indisponibilité du service",
        "Dates Unavailable": "Dates indisponibles",
        "Start Date": "Date de début",
        "End Date": "Date de fin",
        "Facilities Service Is Offered": "Le service des installations est offert",
        "Facility Service Is Provided": "Le service d'installation est fourni",
        "HealthcareService": "HealthCareService",
        "mCSD Organization for Jurisdictions": "Organisation MCSD pour les juridictions",
        "mCSD Organization": "Organisation MCSD",
        "GOFR Facility Update Request": "Demande de mise à jour de l'installation GOFR",
        "mCSD Organization for Facilities": "Organisation MCSD pour les installations",
        "Usage": "Usage",
        "Family": "Famille",
        "Given": "Donnée",
        "Facility Details": "Détails de l'installation",
        "Update Organization": "Mettre à jour l'organisation",
        "Request Updating Facility": "Demander une installation de mise à jour",
        "Exclude From Public View Facility With Below Values": "Exclure de l'installation de vue publique avec des valeurs inférieures",
        "Update Jurisdiction": "Mettre à jour la juridiction",
        "Add/Update Healthcare Service": "Ajouter / mettre à jour le service de soins de santé",
        "Create Change Request": "Créer une demande de changement"
      },
      "hardcoded-texts": {
        "1": "1",
        "2": "2",
        "3": "3",
        "4": "4",
        "5": "5",
        "6": "6",
        "7": "sept",
        "Display": "Affichage",
        "Definition": "Définition",
        "Select App to Uninstall": "Sélectionnez l'application pour désinstaller",
        "Uninstall": "Désinstaller",
        "Install": "Installer",
        "Install New GOFR App": "Installer une nouvelle application GoFR",
        "Cancel": "Annuler",
        "Save": "sauvegarder",
        "Edit": "Éditer",
        "Delete": "Effacer",
        "Editing": "Modification",
        "Yes": "Oui",
        "Search": "Chercher",
        "Add": "Ajouter",
        "Update": "Mise à jour",
        "Export": "Exporter",
        "Name": "Nom",
        "Host": "Hôtesse",
        "Source Type": "Type de Source",
        "User Name": "Nom d'utilisateur",
        "Password": "Mot de passe",
        "TableText": "Lignes par page",
        "Sections": "Sections",
        "Section": "Section",
        "Approve": "Approuver",
        "Reject": "Rejeter",
        "Send Update Request": "Envoyer une demande de mise à jour",
        "Currency": "Devise",
        "This will delete the datasource": "Cela supprimera la source de données",
        "from the database": "de la base de données",
        "Below data source pairs (mapping) will also be deleted": "Les paires de source de données (cartographie) seront également supprimées",
        "no_src_pair_for_src": "Il n'y a aucune paire de sources de données (cartographie) associée à cette source de données",
        "Do you want to proceed and delete": "Voulez-vous procéder et supprimer",
        "Sharing": "Partage",
        "Share": "Partager",
        "limit_share": "Limiter le partage à",
        "About this page": "À propos de cette page",
        "This page let you visualize various data sets you have added into the app as well as synchronize remote servers with the app": "Cette page vous permet de visualiser divers ensembles de données que vous avez ajoutés dans l'application ainsi que de synchroniser les serveurs distants avec l'application",
        "You may come back to this page and add more sources at any time": "Vous pouvez revenir sur cette page et ajouter plus de sources à tout moment",
        "You may proceed to the 'Data Source Pair' page after you have added atleast two data sources": "Vous pouvez passer à la page «paire de sources de données» après avoir ajouté au moins deux sources de données",
        "Use Sync (Update) to pull updated records from the remote server and update the app": "Utilisez Sync (mise à jour) pour extraire des enregistrements mis à jour du serveur distant et mettez à jour l'application",
        "Use Force Full Sync to fetch all data from the remote server and update the app": "Utilisez Force Full Sync pour récupérer toutes les données du serveur distant et mettre à jour l'application",
        "Help": "Aider",
        "Remote Sources": "Sources distantes",
        "Force Full Sync": "Force la synchronisation complète",
        "sync_update": "Sync (mise à jour)",
        "Uploaded Sources": "Sources téléchargées",
        "Export Original CSV": "Exporter CSV original d'origine",
        "Detailed View": "Vue détaillée",
        "Add New Remote Source": "Ajouter une nouvelle source distante",
        "Source Name": "Nom de la source",
        "Base URL": "URL de base",
        "Share this dataset with all other users that are on the same org unit as you": "Partagez cet ensemble de données avec tous les autres utilisateurs qui figurent sur la même unité de travail que vous",
        "Share with other users of the same org unit as yours": "Partagez avec d'autres utilisateurs de la même unité d'organisation que la vôtre",
        "Share with all other users": "Partager avec tous les autres utilisateurs",
        "Limit orgs sharing by user orgid": "Limiter le partage des organisations par l'orgide utilisateur",
        "if activated, other users will see locations (including location children) that has the same location id as their location id": "S'il est activé, d'autres utilisateurs verront des emplacements (y compris les enfants de localisation) qui ont le même ID de localisation que leur identifiant de localisation",
        "Add Blank Source": "Ajouter une source vide",
        "Total": "Totale",
        "Facilities": "Installations",
        "Facility": "Facilité",
        "Jurisdictions": "Juridictions",
        "Created": "Établie",
        "Limited by User Location": "Limité par l'emplacement de l'utilisateur",
        "Shared To All": "Partagé à tous",
        "Shared Users": "Utilisateurs partagés",
        "Permissions": "Autorisation",
        "View": "Voir",
        "Organizations": "Organisations",
        "Healthcare Services": "Services de santé",
        "Detailed View Of": "Vue détaillée de",
        "Data Source": "La source de données",
        "Back to Datasources": "Retour à DataSources",
        "Datasources Contributing To This Data Source": "Les données de données contribuant à cette source de données",
        "Update Permissions": "Mettre à jour les autorisations",
        "View Data": "Afficher les données",
        "Information": "Informations",
        "Data Upload was not successful,review below invalid rows in your CSV": "Le téléchargement de données n'a pas réussi, examinez ci-dessous les lignes non valides dans votre CSV",
        "You are about to upload CSV data into the app, click proceed to upload": "Vous êtes sur le point de télécharger les données CSV dans l'application, cliquez sur Procéder à Télécharger",
        "Warning": "Avertissement",
        "Proceed": "Procéder",
        "Ok": "D'accord",
        "Upload CSV": "Télécharger CSV",
        "Map Headers": "En-têtes de carte",
        "Upload CSV (utf-8 only)": "Télécharger CSV (UTF-8 uniquement)",
        "Select a CSV file and upload": "Sélectionnez un fichier CSV et téléchargez",
        "Continue": "Continuer",
        "Map an appropriate CSV header against those on the app": "Carte un en-tête CSV approprié contre ceux de l'application",
        "Code": "Code",
        "Select": "Sélectionner",
        "Latitude": "Latitude",
        "Longitude": "Longitude",
        "Level": "Niveau",
        "Add More Level": "Ajouter plus de niveau",
        "Upload": "Télécharger",
        "This page let you load data from various sources into the app for reconciliation": "Cette page vous permet de charger des données provenant de diverses sources dans l'application pour la réconciliation",
        "Select to add remote source if you have a DHIS2 or FHIR server that you want to use its data on this app": "Sélectionnez pour ajouter une source distante si vous avez un serveur DHIS2 ou FHIR que vous souhaitez utiliser ses données sur cette application",
        "Select Upload CSV if you have a CSV file and want to upload its data on the app": "Sélectionnez Télécharger CSV si vous avez un fichier CSV et souhaitez télécharger ses données sur l'application",
        "The system requires CSV data to have atleast 2 levels above facility": "Le système exige que les données CSV aient au moins 2 niveaux au-dessus de l'installation",
        "Level 1 is the highest level on the hierarchy i.e Country": "Le niveau 1 est le plus haut niveau de la hiérarchie, c'est-à-dire le pays",
        "Base URL under remote sources section refer to the URL i.e": "URL de base dans la section Sources distantes se référer à l'URL, c'est-à-dire",
        "and not": "et pas",
        "Same applies to DHIS2 base URL": "Il en va de même pour l'URL de base DHIS2",
        "Choose way to add data source": "Choisissez la façon d'ajouter une source de données",
        "Blank Source": "Source vide",
        "Remote Source": "Source éloignée",
        "Are you sure you want to delete this data source pair": "Êtes-vous sûr de vouloir supprimer cette paire de sources de données",
        "Pair creation limit": "Limite de création de paires",
        "You cant create more pairs as this account is limited to one pair only at a time": "Vous ne pouvez pas créer plus de paires car ce compte est limité à une seule paire à la fois",
        "Data sources has different level counts, please map Levels to proceed": "Les sources de données ont des comptes de niveau différents, veuillez cartographier les niveaux pour continuer",
        "Mapping": "Cartographie",
        "This page let you choose a pair of data sources to use for reconciliation": "Cette page vous permet de choisir une paire de sources de données à utiliser pour la réconciliation",
        "Source 1 is the source while source 2 is the target": "La source 1 est la source tandis que la source 2 est la cible",
        "Sharing Pair": "Paire de partage",
        "Create/Choose a pair of data sources to use for reconciliation. Source 1 is the source while source 2 is the target": "Créez / choisissez une paire de sources de données à utiliser pour la réconciliation. La source 1 est la source tandis que la source 2 est la cible",
        "Create Data Source Pair": "Créer une paire de sources de données",
        "Pair Name": "Nom de la paire",
        "Reset": "Réinitialiser",
        "Existing Data Source Pairs": "Paies de source de données existantes",
        "Pair": "Paire",
        "Activate Pair": "Activer la paire",
        "Authentication Failed": "Authentification échouée",
        "Login": "Connexion",
        "Signup": "S'inscrire",
        "Status": "Statut",
        "Source 2": "Source 2",
        "Source 1": "Source 1",
        "Active Reconciliation Pair": "Paire de réconciliation active",
        "Active Partition Stats": "Statistiques de partition active",
        "Facility Registry Datasource": "Registre des installations DataSource",
        "Active Partition": "Partition active",
        "Logout": "Se déconnecter",
        "Services": "Prestations de service",
        "Request Addition of New Facility": "Demander l'ajout de nouvelles installations",
        "Request Update of Facility Details": "Demander la mise à jour des détails de l'installation",
        "Disabling Single Data Source Limit": "Désactivation de la limite de source de données unique",
        "Disabling limiting reconciliation to be done against one choosen data source will also disable the single data source limit, click OK to proceed": "La désactivation de la limitation de la réconciliation à effectuer contre une source de données choisie désactivera également la limite de source de données unique, cliquez sur OK pour continuer",
        "DHIS2 superuser role that can be an administrator of GOFR": "Rôle du superutilisateur DHIS2 qui peut être un administrateur de GOFR",
        "Superuser Role Name": "Nom de rôle de superutilisateur",
        "Select datasource to fix source 2": "Sélectionnez DataSource pour corriger la source 2",
        "This lists only those datasets that have been shared to all users": "Cela répertorie uniquement les ensembles de données qui ont été partagés à tous les utilisateurs",
        "System Configurations": "Configurations système",
        "User Configurations": "Configurations utilisateur",
        "Apply user defined headers when reconciling": "Appliquer les en-têtes définis par l'utilisateur lors de la réconciliation",
        "Admin Configurations": "Configurations d'administration",
        "Perform match based on parent constraint": "Effectuez une correspondance en fonction de la contrainte parentale",
        "Automatch By ID": "Automatch par id",
        "Automatch By Name (when parents differ)": "Automatch par son nom (lorsque les parents diffèrent)",
        "Choose ways datasets can be added": "Choisissez des ensembles de données peuvent être ajoutés",
        "CSV Upload": "Téléchargement de CSV",
        "Remote Servers Sync": "Synchronisation des serveurs distants",
        "Blank Datasource": "Vierge de données",
        "Allow non admin users to share datasets will all users": "Autoriser les utilisateurs non administratifs à partager des ensembles de données sera tous les utilisateurs",
        "Select a data source to serve as Source 2 for all reconciliation": "Sélectionnez une source de données pour servir de source 2 pour toute la réconciliation",
        "This will limit users to perform reconciliations against the chosen data source": "Cela limitera les utilisateurs à effectuer des rapprochements avec la source de données choisie",
        "Source2 Limited To": "Source2 limité à",
        "Change dataset": "Modifier l'ensemble de données",
        "Single data source per user": "Source de données unique par utilisateur",
        "Single data source pair per org unit": "Paire de source de données unique par unité",
        "GOFR Authentication": "Authentification GoFR",
        "Disable Authentication": "Désactiver l'authentification",
        "External Authentication Method": "Méthode d'authentification externe",
        "Pull org units": "Unités d'org de tirage",
        "Share orgs with other users": "Partagez les organisations avec d'autres utilisateurs",
        "Dataset Name": "Nom de l'ensemble de données",
        "Start Pulling": "Commencer à tirer",
        "Self Registration": "Auto-inscription",
        "Enable self registration": "Activer l'auto-inscription",
        "Requires Admin Approval Of Self Registration": "Nécessite l'approbation de l'administration de l'auto-enregistrement",
        "Public Access": "Accès publique",
        "Enable public access": "Activer l'accès du public",
        "Public Datasource": "Source de données publique",
        "Filter Access": "Accès à filtre",
        "Cron Jobs": "Emplois cron",
        "Autosync Below Remote Datasets": "Autosync sous les ensembles de données distants",
        "Cron Time": "Temps cron",
        "SMTP Configuration For Email Notifications": "Configuration SMTP pour les notifications par e-mail",
        "SMTP Host": "Hôte SMTP",
        "SMTP Port": "Port SMTP",
        "SMTP Username": "Nom d'utilisateur SMTP",
        "SMTP Password": "Mot de passe SMTP",
        "SMTP Secured": "SMTP sécurisé",
        "Enable Endpoint Notification when reconciliation is done": "Activer la notification des points de terminaison lorsque la réconciliation est effectuée",
        "End point to send notification when reconciliation is done": "Point final à envoyer une notification lorsque la réconciliation est effectuée",
        "End point URL": "URL du point final",
        "End point Username": "Nom d'utilisateur de point final",
        "End point Password": "Mot de passe du point final",
        "Reconcile": "Réconcilier",
        "Wait for upload to finish": "Attendez le téléchargement pour terminer",
        "This page let you view what you have uploaded or synchronized from a remote server": "Cette page vous permet de voir ce que vous avez téléchargé ou synchronisé à partir d'un serveur distant",
        "Use the tree to filter grid data": "Utilisez l'arbre pour filtrer les données de la grille",
        "Edit Location": "Modifier l'emplacement",
        "Parent": "Parente",
        "Source 1 Data Tree": "Source 1 arbre de données",
        "Source 2 Data Tree": "Source 2 Arbre de données",
        "Source 1 Data Grid": "Source 1 Grille de données",
        "FHIR Export": "Exportation FHIR",
        "CSV Export": "Exportation de CSV",
        "Source2 Unmatched": "Source2 inégalée",
        "Source1 Unmatched": "Source1 inégalé",
        "Matched": "Assortie",
        "Select file type to download": "Sélectionnez le type de fichier à télécharger",
        "Source 2 Location": "Emplacement de la source 2",
        "ID": "IDENTIFIANT",
        "Geo Dist (Miles)": "Geo Dist (miles)",
        "Score": "Score",
        "Comment": "Commentaire",
        "Add comment for this flag if any": "Ajoutez un commentaire pour ce drapeau si",
        "Flag Comment": "Commentaire du drapeau",
        "Matching": "Correspondant à",
        "Parents": "Parentes",
        "Flag": "Drapeau",
        "Mark the selected item as a match to be reviewed": "Marquez l'élément sélectionné comme une correspondance à examiner",
        "Save Match": "Sauver la correspondance",
        "Save the selected item as a match": "Enregistrez l'élément sélectionné comme correspondance",
        "No Match": "Aucune concordance",
        "Save this Source 1 location as having no match": "Enregistrez cet emplacement à la source 1 comme n'ayant pas de correspondance",
        "Ignore": "Ignorer",
        "Mark this source 1 location as being ignored": "Marquez cette source 1 emplacement comme étant ignoré",
        "Show All Suggestions": "Montrer toutes les suggestions",
        "See all possible choices ignoring the score": "Voir tous les choix possibles en ignorant le score",
        "Back": "Retour",
        "Return without saving": "Retour sans économiser",
        "Reconciling": "Réconciliation",
        "Recalculate Scores": "Recalculer les scores",
        "Saving matches for": "Économiser des matchs pour",
        "This page let you map source 1 data with those in source2": "Cette page vous permet de cartographier les données de Source 1 avec celles de Source2",
        "Source 1 refer to the data source name selected as source 1 under data source pair section": "Source 1 Reportez-vous au nom de source de données sélectionné comme source 1 dans la section de la paire de sources de données",
        "Source 2 refer to the data source name selected as source 2 under data source pair section": "Source 2 Reportez-vous au nom de source de données sélectionné comme source 2 dans la section de la paire de sources de données",
        "After breaking a match, you will need to recalculate scores for the app to load potential matches of the broken location": "Après avoir cassé un match, vous devrez recalculer les scores pour l'application pour charger des correspondances potentielles de l'emplacement cassé",
        "FLAGGED Locations are the locations that will need to be reviewed before they are saved as matches": "Les emplacements signalés sont les emplacements qui devront être examinés avant d'être enregistrés sous forme de correspondances",
        "NO MATCHES - these are locations that do not matches anything from source 2": "Pas de correspondance - Ce sont des emplacements qui ne correspondent à rien de la source 2",
        "Source 1 Reconciliation Status": "Source 1 Statut de réconciliation",
        "Unmatched": "Incomparable",
        "Flagged": "Signalé",
        "Source 1 Unmatched": "Source 1 inégalée",
        "Source 2 Unmatched": "Source 2 inégalée",
        "Source 2 Reconciliation Status": "Source 2 Statut de réconciliation",
        "Not in Source 1": "Pas dans la source 1",
        "MATCHED": "ASSORTIE",
        "NO MATCH": "AUCUNE CONCORDANCE",
        "IGNORED": "IGNORÉE",
        "FLAGGED": "Signalé",
        "Break Match": "Match de pause",
        "Break Ignore": "Break Ignore",
        "Confirm Match": "Confirmer le match",
        "Release": "Sortie",
        "Proceed to": "Procéder à",
        "Reconciliation Status": "Statut de réconciliation",
        "Add New User": "Ajouter un nouvel utilisateur",
        "First Name": "Prénom",
        "Middle Names": "Deuxième prénom",
        "Surname": "Nom de famille",
        "Phone": "Téléphoner",
        "Email": "E-mail",
        "Re-type Password": "Retaper le mot de passe",
        "Role": "Rôle",
        "This page let you visualize the mapping status for various levels": "Cette page vous permet de visualiser l'état de cartographie pour différents niveaux",
        "Right boxes shows reconciliation status for a selected level": "Les boîtes de droite affiche l'état de réconciliation pour un niveau sélectionné",
        "Left boxes shows reconciliation status for a all levels": "Les boîtes de gauche montrent le statut de réconciliation pour tous les niveaux",
        "The search box let you search locations for a selected level only": "La zone de recherche vous permettez de rechercher les emplacements pour un niveau sélectionné uniquement",
        "Only": "Seulement",
        "Source 1 Matched": "Source 1 correspondant",
        "Source 1 Flagged": "Source 1 signalée",
        "Source 2 Matched": "Source 2 correspondant",
        "Source 2 Flagged": "Source 2 signalée",
        "Mark Reconciliation As Done": "Marquez la réconciliation comme fait",
        "Mark Reconciliation As UnDone": "Marquer la réconciliation comme annulée",
        "Source 1 Not Mapped": "Source 1 non mappée",
        "Source 1 NO MATCH": "Source 1 ignoré",
        "Create Account": "Créer un compte",
        "Clear": "Dégager",
        "Back To Login": "Retour connexion",
        "Change": "Changer",
        "Old Password": "ancien mot de passe",
        "Change Password": "Changer le mot de passe",
        "Tasks assigned to role": "Tâches affectées au rôle",
        "Select role to edit tasks": "Sélectionnez un rôle pour modifier les tâches",
        "Select Role": "Sélectionner un rôle",
        "User": "Utilisatrice",
        "Approve/Reject": "Approuver / rejeter",
        "Deactivate": "Désactiver",
        "Activate": "Activer",
        "Reset Password": "réinitialiser le mot de passe",
        "Other Name": "Autre nom",
        "create_choose_pair": "Créez / choisissez une paire de sources de données à utiliser pour la réconciliation. La source 1 est la source tandis que la source 2 est la cible",
        "Source 1 No Match": "",
        "Installed Apps": "Applications installées",
        "Advanced Options": "Options avancées",
        "Last Sync": "Dernière synchronisation",
        "Owner": "Propriétaire",
        "Shared To": "Partagé avec",
        "Created Time": "Temps créé",
        "Action": "Action",
        "Source1 Location": "Emplacement Source1",
        "Source1 ID": "ID de source1",
        "Source2 Location": "Emplacement Source2",
        "Source2 ID": "ID de source2",
        "Match Comment": "Commentaire de correspondance",
        "Location": "Emplacement",
        "Users List": "Liste des utilisateurs",
        "Last Updated Time": "Dernière heure mise à jour",
        "Enabled": "Activé"
      },
      "title": "Faire en sorte",
      "site": "Démo"
    }
  }
}